// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-project-js": () => import("./../../../src/pages/about/project.js" /* webpackChunkName: "component---src-pages-about-project-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-account-secure-js": () => import("./../../../src/pages/my-account-secure.js" /* webpackChunkName: "component---src-pages-my-account-secure-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-overviews-js": () => import("./../../../src/templates/overviews.js" /* webpackChunkName: "component---src-templates-overviews-js" */),
  "component---src-templates-survey-js": () => import("./../../../src/templates/survey.js" /* webpackChunkName: "component---src-templates-survey-js" */)
}

